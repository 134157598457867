import React, { useState } from 'react'
import Error from './ErrorMessage'
import DisplaySuccess from './SuccessMessage'

const ForgotPassword = () => {
    const [forgot, setForgot] = useState({
        email: '',
        error: {},
        loading: false,
        success: false,
    })

    const saveToForgot = e => {
        const { name, value } = e.target
        setForgot(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    async function password(forgot) {
        setForgot(prev => ({
            ...prev,
            loading: true,
            success: false,
            error: {},
        }))
        var formData = new FormData()
        formData.append('email', forgot.email)
        const requestOptions = {
            method: 'POST',
            body: formData,
        }

        await fetch(
            'https://scooters.bozell.com/auth/forgot-password',
            requestOptions
        )
            .then(response => response.text())
            .then(result => {
                if (result) {
                    if (
                        JSON.parse(result) &&
                        JSON.parse(result).ok &&
                        JSON.parse(result).ok === true
                    ) {
                        setForgot(prev => ({
                            ...prev,
                            loading: false,
                            success: true,
                        }))
                    } else {
                        setForgot(prev => ({
                            ...prev,
                            loading: false,
                            success: false,
                            error: {
                                message:
                                    "We couldn't find an account using that email address.",
                            },
                        }))
                    }
                }
            })
    }

    return (
        <div className="log-in-card">
            <form
                method="post"
                onSubmit={async e => {
                    e.preventDefault()
                    await password(forgot, {
                        variables: { ...forgot },
                    })
                }}
            >
                <Error error={forgot.error} />
                {forgot.success && (
                    <DisplaySuccess
                        message={`An email was sent to the address you provided.`}
                    />
                )}
                <fieldset disabled={forgot.loading} aria-busy={forgot.loading}>
                    <h3>Reset your password</h3>
                    <p>
                        Enter the email address you used to create your account
                        below. You will be sent an email with further
                        instructions.
                    </p>
                    <div className="input-container">
                        <input
                            required
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={forgot.email}
                            onChange={saveToForgot}
                        />
                        <label htmlFor="email">Email</label>
                    </div>

                    <button className="btn" type="submit">
                        Submit
                    </button>
                </fieldset>
            </form>
        </div>
    )
}

export default ForgotPassword
