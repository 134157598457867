import gql from 'graphql-tag'

const CREATE_REGISTRATION = gql`
    mutation createGrowRegistrations2020(
        $RegistrationType: String
        $OtherRegType: String
        $FirstName: String
        $LastName: String
        $Email: String
        $Phone: String
        $Address1: String
        $Address2: String
        $City: String
        $State: ENUM_GROWREGISTRATIONS2020_STATE
        $Zip: String
        $Company: String
        $Role: String
        $Question: String
        $Confirmation: Long!
    ) {
        createGrowRegistrations2020(
            input: {
                data: {
                    RegistrationType: $RegistrationType
                    OtherRegType: $OtherRegType
                    FirstName: $FirstName
                    LastName: $LastName
                    EmailAddress: $Email
                    Phone: $Phone
                    Address1: $Address1
                    Address2: $Address2
                    City: $City
                    State: $State
                    Zip: $Zip
                    Company: $Company
                    Role: $Role
                    Question: $Question
                    Confirmation: $Confirmation
                }
            }
        ) {
            growRegistrations2020 {
                id
            }
        }
    }
`

export { CREATE_REGISTRATION }
