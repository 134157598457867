import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const REGISTRATION_QUERY = gql`
    query registrations(
        $sort: String
        $limit: Int
        $start: Int
        $where: JSON!
    ) {
        registrations(
            sort: $sort
            limit: $limit
            start: $start
            where: $where
        ) {
            id
            RegistrationType
            FranchiseeName
            FranchiseeType
            FranchiseeTypeOther
            PersonalInformation {
                FirstName
                LastName
                Email
                Title
                PhoneNumber
            }
            MailingAddress {
                Address
                Address2
                City
                State
                Zip
            }
            AlternateContact {
                FirstName
                LastName
                Email
                PhoneNumber
            }
            HotelInformation {
                RequireHotel
                SharingRoom
                SharingWith
            }
            PaymentDetails {
                CardNumber
                TransactionId
                Paid
                Amount
            }
            user {
                username
                id
            }
            InterestedInTour
            Confirmation
        }
    }
`

function test(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
    var str = ''

    for (var i = 0; i < array.length; i++) {
        var line = ''
        for (var index in array[i]) {
            if (line !== '') line += ','

            line += array[i][index]
        }

        str += line + '\r\n'
    }

    return str
}

const Export = () => {
    var exportArray = []

    const { data } = useQuery(REGISTRATION_QUERY, {
        variables: { where: {} },
        onCompleted: function() {
            const registrations = data.registrations

            exportArray.push([])
            exportArray[0]['RegType'] = 'RegType'
            exportArray[0]['Confirmation'] = 'Confirmation'
            exportArray[0]['FranchiseName'] = 'FranchiseName'
            exportArray[0]['FranchiseeType'] = 'FranchiseeType'
            exportArray[0]['Title'] = 'Title'
            exportArray[0]['FirstName'] = 'FirstName'
            exportArray[0]['LastName'] = 'LastName'
            exportArray[0]['Email'] = 'Email'
            exportArray[0]['PhoneNumber'] = 'PhoneNumber'
            exportArray[0]['Address'] = 'Address'
            exportArray[0]['Address2'] = 'Address2'
            exportArray[0]['City'] = 'City'
            exportArray[0]['State'] = 'State'
            exportArray[0]['Zip'] = 'Zip'
            exportArray[0]['RequireHotel'] = 'RequireHotel'
            exportArray[0]['SharingRoom'] = 'SharingRoom'
            exportArray[0]['SharingWith'] = 'SharingWith'
            exportArray[0]['TransactionId'] = ''
            exportArray[0]['Paid'] = 'Paid'
            exportArray[0]['Amount'] = 'Amount'
            exportArray[0]['InterestedInTour'] = 'InterestedInTour'

            var x
            var y = 0

            for (x = 1; x <= registrations.length; x++) {
                exportArray.push([])

                const reg = data.registrations[y]

                exportArray[x]['RegType'] = reg.RegistrationType
                exportArray[x]['Confirmation'] = reg.Confirmation
                exportArray[x]['FranchiseName'] = reg.FranchiseeName
                exportArray[x]['FranchiseeType'] = reg.FranchiseeType
                    ? reg.FranchiseeType
                    : reg.FranchiseeTypeOther

                if (reg.PersonalInformation) {
                    const p = reg.PersonalInformation
                    exportArray[x]['Title'] = p.Title
                    exportArray[x]['FirstName'] = p.FirstName
                    exportArray[x]['LastName'] = p.LastName
                    exportArray[x]['Email'] = p.Email
                    exportArray[x]['PhoneNumber'] = p.PhoneNumber
                } else {
                    exportArray[x]['Title'] = ''
                    exportArray[x]['FirstName'] = ''
                    exportArray[x]['LastName'] = ''
                    exportArray[x]['Email'] = ''
                    exportArray[x]['PhoneNumber'] = ''
                }

                if (reg.MailingAddress) {
                    const m = reg.MailingAddress
                    exportArray[x]['Address'] = m.Address
                    exportArray[x]['Address2'] = m.Address2
                    exportArray[x]['City'] = m.City
                    exportArray[x]['State'] = m.State
                    exportArray[x]['Zip'] = m.Zip
                } else {
                    exportArray[x]['Address'] = ''
                    exportArray[x]['Address2'] = ''
                    exportArray[x]['City'] = ''
                    exportArray[x]['State'] = ''
                    exportArray[x]['Zip'] = ''
                }

                if (reg.HotelInformation) {
                    const h = reg.HotelInformation
                    exportArray[x]['RequireHotel'] = h.RequireHotel
                    exportArray[x]['SharingRoom'] = h.SharingRoom
                    exportArray[x]['SharingWith'] = h.SharingWith
                } else {
                    exportArray[x]['RequireHotel'] = ''
                    exportArray[x]['SharingRoom'] = ''
                    exportArray[x]['SharingWith'] = ''
                }

                if (reg.HotelInformation) {
                    const m = reg.PaymentDetails
                    exportArray[x]['TransactionId'] = m.PaymentDetails
                        ? m.PaymentDetails
                        : ''
                    exportArray[x]['Paid'] = m.Paid ? 'Yes' : 'No'
                    exportArray[x]['Amount'] = m.Amount ? m.Amount : ''
                } else {
                    exportArray[x]['TransactionId'] = ''
                    exportArray[x]['Paid'] = ''
                    exportArray[x]['Amount'] = ''
                }

                exportArray[x]['InterestedInTour'] = reg.InterestedInTour
                    ? 'Yes'
                    : 'No'

                y++
            }

            var temp = test(exportArray)

            var encodedUri = encodeURI(temp)

            // let csvContent =
            //     'data:text/csv;charset=utf-8,' +
            //     exportArray.map(e => e.join(',')).join('\n')
            // var encodedUri = encodeURI(csvContent)
            // window.open(encodedUri)
            // window.location.href = 'data:text/csv;charset=utf-8,' + encodedUri

            encodedUri = encodeURI('data:text/csv;charset=utf-8' + temp)
            var link = document.createElement('a')
            link.setAttribute('href', encodedUri)
            link.setAttribute('download', 'registration_export.csv')
            document.body.appendChild(link) // Required for FF

            link.click()
        },
    })

    return (
        <div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
      .header, footer { display:none }
    `,
                }}
            />
            <p>
                You're export is being generated. You can close this tab/window
                when complete.
            </p>
        </div>
    )
}

export default Export
