import React from 'react'
import Img from 'gatsby-image'

const AgendaItem = ({ item }) => {
    const { Time, Title, Speaker, SpeakerImages, IconImage } = item

    const renderSpeakerImages = images => {
        const length = images.length - 1
        return images.map((image, index) => {
            const translateX = (length - index) * 8
            const stackingOrder = length - index

            return (
                <Img
                    key={image.id}
                    fluid={image.imageFile.childImageSharp.fluid}
                    style={{
                        width: `32px`,
                        height: `32px`,
                        boxShadow: `2px 0 0 rgba(255,255,255,1)`,
                        borderRadius: `50%`,
                        transform: `translateX( ${translateX}px )`,
                        zIndex: `${stackingOrder}`,
                    }}
                />
            )
        })
    }
    return (
        <div className="agenda-item">
            <div className="agenda-item__inner">
                <div className="agenda-item__info">
                    <span>{Time}</span>
                    <p>{Title}</p>
                </div>
                <div className="agenda-item__meta">
                    {Speaker && <p>{Speaker}</p>}

                    {SpeakerImages && renderSpeakerImages(SpeakerImages)}

                    {IconImage && (
                        <Img
                            fluid={IconImage.imageFile.childImageSharp.fluid}
                            style={{ width: `32px`, height: `auto` }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default AgendaItem
