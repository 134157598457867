import React from 'react'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

const GenericSection = ({ input }) => {
    return (
        <section className="the-venue">
            <div className="the-venue__inner">
                <div className="the-venue__img">
                    {input.GenericImage &&
                        input.GenericImage[0] &&
                        input.GenericImage[0].imageFile && (
                            <Img
                                fluid={
                                    input.GenericImage[0].imageFile
                                        .childImageSharp.fluid
                                }
                            />
                        )}
                </div>
                <div className="the-venue__content">
                    <div className="the-venue__content-wrapper">
                        <h2>{input.Headline}</h2>
                        <ReactMarkdown source={input.Copy} />
                        <p>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://scooters.bozell.com/uploads/f004ec76d7f74c58802636e211617c9e.pdf"
                            >
                                View the conference agenda here.
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GenericSection
