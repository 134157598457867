import React, { useState } from 'react'
import Error from './ErrorMessage'
import DisplaySuccess from './SuccessMessage'

const ForgotPassword = () => {
    function getQueryVariable(variable) {
        if (typeof window !== `undefined`) {
            var query = window.location.search.substring(1)
            var vars = query.split('&')
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=')
                if (pair[0] === variable) {
                    return pair[1]
                }
            }
            return false
        }
    }

    const [forgot, setForgot] = useState({
        email: '',
        error: {},
        loading: false,
        success: false,
        code: getQueryVariable('code'),
        password: '',
        password2: '',
    })

    const saveToForgot = e => {
        const { name, value } = e.target
        setForgot(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    async function password(forgot) {
        setForgot(prev => ({
            ...prev,
            loading: true,
            success: false,
            error: {},
        }))
        var formData = new FormData()
        formData.append('code', forgot.code)
        formData.append('password', forgot.password)
        formData.append('passwordConfirmation', forgot.password2)
        const requestOptions = {
            method: 'POST',
            body: formData,
        }

        console.log(forgot)
        await fetch(
            'https://scooters.bozell.com/auth/reset-password',
            requestOptions
        )
            .then(response => response.text())
            .then(result => {
                if (result) {
                    if (JSON.parse(result).jwt) {
                        setForgot(prev => ({
                            ...prev,
                            loading: false,
                            success: true,
                        }))
                    } else {
                        setForgot(prev => ({
                            ...prev,
                            loading: false,
                            success: false,
                            error: {
                                message: JSON.parse(result).message[0]
                                    .messages[0].message,
                            },
                        }))
                    }
                }
            })
    }

    return (
        <div className="log-in-card">
            <form
                method="post"
                onSubmit={async e => {
                    e.preventDefault()
                    await password(forgot, {
                        variables: { ...forgot },
                    })
                }}
            >
                <Error error={forgot.error} />
                {forgot.success && (
                    <DisplaySuccess message={`Your password has been reset.`} />
                )}
                <fieldset disabled={forgot.loading} aria-busy={forgot.loading}>
                    <h3>Reset your password</h3>
                    <p>Enter your new password below.</p>
                    <div className="input-container">
                        <input
                            required
                            type="password"
                            name="password"
                            placeholder=""
                            value={forgot.password}
                            onChange={saveToForgot}
                        />
                        <label htmlFor="password">Password</label>
                    </div>
                    <div className="input-container">
                        <input
                            required
                            type="password"
                            name="password2"
                            placeholder=""
                            value={forgot.password2}
                            onChange={saveToForgot}
                        />
                        <label htmlFor="password2">Confirm Password</label>
                    </div>

                    <button className="btn" type="submit">
                        Submit
                    </button>
                </fieldset>
            </form>
        </div>
    )
}

export default ForgotPassword
