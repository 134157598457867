import React, { useEffect } from 'react'
import Error from './ErrorMessage'
import IMask from 'imask'

const RegistrationInformation = ({
    mutationError,
    saveToState,
    state,
    submit,
    nextStep,
    prevStep,
    isReadOnly,
    scrollToTop,
}) => {
    const theForm = React.createRef()

    const maskedRef = React.useRef()
    useEffect(() => {
        if (typeof document !== 'undefined') {
            IMask(maskedRef.current, {
                mask: '000-000-0000',
            })
        }
    })

    return (
        <form
            id="registration"
            method="post"
            onSubmit={async e => {
                scrollToTop()
                e.preventDefault()
                await submit({
                    variables: { ...state },
                })
                nextStep()
            }}
            ref={theForm}
        >
            <Error error={mutationError} />
            <div className="fieldset-wrapper">
                <div className="input-container input-container--select">
                    <select
                        onBlur={saveToState}
                        onChange={saveToState}
                        name="RegistrationType"
                        id="RegistrationType"
                        value={state.RegistrationType}
                        required
                    >
                        <option value="">Select One</option>
                        <option value="Associate">Associate</option>
                        <option value="Sponsor">Sponsor</option>
                        <option value="Franchisee / Store Manager">
                            Franchisee / Store Manager
                        </option>
                        <option value="Other">Other</option>
                    </select>
                    <label htmlFor="RegistrationType">Registration Type</label>
                </div>
                {state.RegistrationType === 'Other' && (
                    <div className="input-container">
                        <input
                            required
                            type="text"
                            name="OtherRegType"
                            id="OtherRegType"
                            placeholder="Please Specify"
                            value={state.OtherRegType}
                            onChange={saveToState}
                        />
                        <label htmlFor="OtherRegType">
                            Other Registration Type
                        </label>
                    </div>
                )}

                <div className="input-container">
                    <input
                        required
                        type="text"
                        name="FirstName"
                        placeholder=""
                        value={state.FirstName}
                        onChange={saveToState}
                    />
                    <label htmlFor="FirstName">First Name</label>
                </div>
                <div className="input-container">
                    <input
                        required
                        type="text"
                        name="LastName"
                        placeholder=""
                        value={state.LastName}
                        onChange={saveToState}
                    />
                    <label htmlFor="LastName">Last Name</label>
                </div>
                <div className="input-container">
                    <input
                        required
                        type="text"
                        name="Email"
                        placeholder=""
                        value={state.Email}
                        onChange={saveToState}
                    />
                    <label htmlFor="Email">Email Address</label>
                </div>

                <div className="input-container input-container--half">
                    <input
                        required
                        type="tel"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        name="Phone"
                        placeholder="###-###-####"
                        value={state.Phone}
                        onChange={saveToState}
                        onAccept={saveToState}
                        ref={maskedRef}
                        autoComplete="off"
                    />
                    <label htmlFor="Phone">Mobile Phone Number</label>
                </div>

                <div className="input-container">
                    <input
                        required
                        type="text"
                        name="Address1"
                        placeholder=""
                        value={state.Address1}
                        onChange={saveToState}
                    />
                    <label htmlFor="Address1">Home Address</label>
                </div>

                <div className="input-container">
                    <input
                        type="text"
                        name="Address2"
                        placeholder=""
                        value={state.Address2}
                        onChange={saveToState}
                    />
                    <label htmlFor="Address2">Home Address 2</label>
                </div>

                <div className="input-container">
                    <input
                        required
                        type="text"
                        name="City"
                        placeholder=""
                        value={state.City}
                        onChange={saveToState}
                    />
                    <label htmlFor="City">City</label>
                </div>

                <div className="input-container input-container--half input-container--select">
                    <select
                        onChange={saveToState}
                        onBlur={saveToState}
                        name="State"
                        value={state.State}
                        required
                    >
                        <option value="">Select One</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                    </select>
                    <label htmlFor="State">State</label>
                </div>

                <div className="input-container input-container--half">
                    <input
                        required
                        type="text"
                        name="Zip"
                        placeholder=""
                        value={state.Zip}
                        onChange={saveToState}
                        pattern="[0-9]{5}"
                    />
                    <label htmlFor="Zip">Zip</label>
                </div>

                <div className="input-container">
                    <input
                        type="text"
                        name="Company"
                        placeholder=""
                        value={state.Company}
                        onChange={saveToState}
                    />
                    <label htmlFor="Company">Company</label>
                </div>

                <div className="input-container">
                    <input
                        type="text"
                        name="Role"
                        placeholder=""
                        value={state.Role}
                        onChange={saveToState}
                    />
                    <label htmlFor="Role">Role at Company</label>
                </div>

                <div className="input-container">
                    <textarea
                        name="Question"
                        placeholder=""
                        value={state.Question}
                        onChange={saveToState}
                    />
                    <label htmlFor="Role">
                        Have a question for the Scooter's Coffee corporate team?
                        We'll do our best to select a few as part of the live
                        event!
                    </label>
                </div>

                <p>
                    For additional information or questions about GROW 2020,
                    email{' '}
                    <a href="mailto:scooterscoffee@planitomaha.com">
                        scooterscoffee@planitomaha.com
                    </a>
                    .
                </p>

                <div className="button-container">
                    <button className="btn" type="submit">
                        Submit Registration
                    </button>
                </div>
            </div>
        </form>
    )
}

export default RegistrationInformation
