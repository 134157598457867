import React from 'react'
import ResetPassword from '../components/ResetPassword'


const ForgotPasswordPage = () => {
    return (
        <>
            <ResetPassword />
        </>
    )
}

export default ForgotPasswordPage
