import React, { useEffect } from 'react'

import Img from 'gatsby-image'

const LargeHero = ({ input }) => {
    useEffect(() => {})

    return (
        <>
            <section className="hero hero--large">
                <div className="hero__inner">
                    <div className="hero__image">
                        {input.HeaderImage && input.HeaderImage.imageFile && (
                            <Img
                                fluid={
                                    input.HeaderImage.imageFile.childImageSharp
                                        .fluid
                                }
                            />
                        )}
                    </div>
                    <div className="hero__overlay"></div>
                    <div className="hero__content">
                        <h1>{input.Headline}</h1>
                        <p>{input.SubHeadline}</p>
                        <p>
                            <strong>{input.Dates}</strong>
                        </p>

                        {/* <Link className="btn" to="/attendee-registration">
                            Register Now
                        </Link> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default LargeHero
