// import ApolloClient from 'apollo-boost'
import { ApolloClient } from 'apollo-client'
import fetch from 'isomorphic-fetch'
// import { InMemoryCache } from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import Cookies from 'universal-cookie'
import jwt from 'jsonwebtoken'

const cache = new InMemoryCache({
    addTypename: false,
})
const link = new HttpLink({
    uri: `${process.env.GATSBY_DASHBOARD}/graphql`,
    headers: {
        Authorization: `Bearer ${process.env.GATSBY_STRAPI_KEY}`,
    },
})

const cookies = new Cookies()
const token = cookies.get('token')
const tokenData =
    cookies.get('token') && jwt.verify(token, process.env.GATSBY_APP_SECRET)

let data

if (tokenData) {
    data = {
        isLoggedIn: true,
        username: tokenData.username,
        userid: tokenData.userid,
    }
} else {
    data = {
        isLoggedIn: false,
        username: '',
        useremail: '',
        userid: '',
    }
}

export const client = new ApolloClient({
    // request: operation => {
    //     operation.setContext({
    //         headers: {
    //             Authorization: `Bearer ${process.env.GATSBY_STRAPI_KEY}`,
    //         },
    //     })
    // },
    cache,
    link: link,
    uri: `${process.env.GATSBY_DASHBOARD}/graphql`,
    fetch,
})

cache.writeData({
    data,
})
