import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import Success from '../components/Success'
import RegistrationInformation from './RegistrationInformation'
import { CREATE_REGISTRATION } from './graphql'
import { useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const ASSETS_QUERY = graphql`
    query AssetsQuery {
        franke: file(relativePath: { eq: "franke.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        burrito: file(relativePath: { eq: "burrito-kitchen.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        grubhub: file(relativePath: { eq: "grubhub.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        lockton: file(relativePath: { eq: "lockton.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        monin: file(relativePath: { eq: "monin.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        par: file(relativePath: { eq: "par.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        thc: file(relativePath: { eq: "thc.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        seda: file(relativePath: { eq: "seda.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

function scrollToTop(go) {
    if (typeof window !== `undefined`) {
        if (typeof go === 'function') {
            go()
        }
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
}

const Registration = () => {
    const {
        seda,
        thc,
        par,
        monin,
        lockton,
        grubhub,
        burrito,
        franke,
    } = useStaticQuery(ASSETS_QUERY)

    const [state, setState] = useState({
        step: 1,
        RegistrationType: '',
        OtherRegType: '',
        FirstName: '',
        LastName: '',
        Email: '',
        Phone: '',
        Address1: '',
        Address2: '',
        City: '',
        State: '',
        Zip: '',
        Company: '',
        Role: '',
        Question: '',
        Confirmation: new Date().getTime(),
    })

    useEffect(() => {
        if (state.step === 2) {
            document
                .getElementsByClassName('the-venue')[0]
                .classList.add('hidden')
        } else {
            document
                .getElementsByClassName('the-venue')[0]
                .classList.remove('hidden')
        }
    })

    const saveToState = e => {
        const { name, value } = e.target
        setState(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    const nextStep = () => {
        setState(prev => ({
            ...prev,
            step: state.step + 1,
        }))
    }

    const prevStep = () => {
        scrollToTop()
        setState(prev => ({
            ...prev,
            step: state.step - 1,
        }))
    }

    const [
        submit,
        { loading: mutationLoading, error: mutationError },
    ] = useMutation(CREATE_REGISTRATION, {
        onCompleted: data => {
            if (!state.id) {
                state.id =
                    data.createGrowRegistrations2020.growRegistrations2020.id
            }
        },
    })

    const renderStep = () => {
        switch (state.step) {
            case 1:
                return (
                    <>
                        <p>
                            We invite you to join us for the Scooter's Coffee
                            GROW 2020 Virtual Franchise Conference
                            <br />
                            at 11 a.m. - 3 p.m. CST Tuesday, September 1 -
                            Wednesday, September 2, 2020.
                        </p>
                        <p>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://scooters.bozell.com/uploads/f004ec76d7f74c58802636e211617c9e.pdf"
                            >
                                View the conference agenda here.
                            </a>
                        </p>
                        <p>
                            Attendees must register by August 21, 2020 to
                            receive conference materials.
                        </p>
                        <p>
                            Please list a physical mailing address. P.O. Boxes
                            are not allowed for shipping purposes.
                        </p>

                        <RegistrationInformation
                            mutationLoading={mutationLoading}
                            mutationError={mutationError}
                            state={state}
                            saveToState={saveToState}
                            submit={submit}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            setState={setState}
                            scrollToTop={scrollToTop}
                        />

                        <h3>Our Sponsors</h3>
                        <div id="sponsors">
                            <div class="sponsor-block">
                                <h4>Premium Sponsors</h4>
                                <div class="sponsor-logos">
                                    <div class="logo">
                                        <Img
                                            fluid={franke.childImageSharp.fluid}
                                        />
                                    </div>
                                    <div class="logo">
                                        <Img
                                            fluid={
                                                lockton.childImageSharp.fluid
                                            }
                                        />
                                    </div>
                                </div>
                                <div class="sponsor-logos">
                                    <div class="logo">
                                        <Img
                                            fluid={par.childImageSharp.fluid}
                                        />
                                    </div>
                                    <div class="logo">
                                        <Img
                                            fluid={seda.childImageSharp.fluid}
                                            style={{
                                                margin: '4rem',
                                                maxHeight: 'calc(40vh - 4rem)',
                                            }}
                                            imgStyle={{ objectFit: 'contain' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="sponsor-block">
                                <h4>Diamond Sponsors</h4>
                                <div class="sponsor-logos">
                                    <div class="logo">
                                        <Img
                                            fluid={
                                                burrito.childImageSharp.fluid
                                            }
                                        />
                                    </div>{' '}
                                    <div class="logo">
                                        <Img
                                            fluid={monin.childImageSharp.fluid}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="sponsor-block">
                                <h4>Platinum Sponsors</h4>
                                <div class="sponsor-logos">
                                    <div class="logo">
                                        <Img
                                            fluid={
                                                grubhub.childImageSharp.fluid
                                            }
                                        />
                                    </div>
                                    <div class="logo">
                                        <Img
                                            fluid={thc.childImageSharp.fluid}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )

            case 2: {
                return (
                    <Success
                        prevStep={prevStep}
                        state={state}
                        scrollToTop={scrollToTop}
                    />
                )
            }

            default:
                return <p></p>
        }
    }

    const renderHeadline = () => {
        switch (state.step) {
            case 1:
                return <h3>Get Registered</h3>
            case 2:
                return <h3>Registration Complete</h3>
            default:
                return <></>
        }
    }

    return (
        <div className="registration-wrapper">
            {renderHeadline()}

            <div className="registration-container">
                <div>{renderStep()}</div>
            </div>
        </div>
    )
}

export default Registration
