import React from 'react'
import Img from 'gatsby-image'

const PastSponsors = ({ input }) => {
    return (
        <section className="past-sponsors">
            <div className="past-sponsors__inner">
                <p>Our Past Sponsors</p>
                {input.Images.map(image => (
                    <div key={image.id} className="past-sponsors__img">
                        <Img fluid={image.imageFile.childImageSharp.fluid} />
                    </div>
                ))}
            </div>
        </section>
    )
}

export default PastSponsors
