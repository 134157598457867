import React, { useEffect } from 'react'
import { Link } from 'gatsby'

const Success = ({ state, prevStep }) => {
    const message = {
        to: state.Email,
        from: 'scooterscoffee@planitomaha.com',
        subject: "Scooter's Coffee GROW 2020 Virtual Franchise Conference",
        ...state,
    }

    const GetMessage = () => {
        return `https://scooters.bozell.com/send/home/confirmation2020?json=${encodeURIComponent(
            JSON.stringify(message)
        )}`
    }

    const GetSendMessage = () => {
        return `https://scooters.bozell.com/send/home/sendconfirmation2020?json=${encodeURIComponent(
            JSON.stringify(message)
        )}`
    }

    async function Confirmation() {
        var formData = new FormData()

        Object.keys(message).forEach(function(key) {
            formData.append(key, message[key])
        })

        fetch(GetSendMessage().toString())
    }
    const useMountEffect = fun => useEffect(fun, [])
    useMountEffect(() => {
        Confirmation()
    }, [Confirmation])

    return (
        <fieldset>
            <p>
                Thank you for registering for Scooter's Coffee GROW 2020 Virtual
                Franchise Conference.{' '}
            </p>

            <p>
                Your confirmation number is:{' '}
                <strong>{state.Confirmation}</strong>
            </p>

            <p>
                A confirmation email was sent to <strong>{state.Email}</strong>.{' '}
                <a
                    target="_blank"
                    className="red"
                    rel="noopener noreferrer"
                    href={GetMessage().toString()}
                >
                    Click here to view it in a browser.
                </a>
            </p>

            <p>We look forward to seeing you soon!</p>

            <div class="button-container__success">
                <p>
                    <Link
                        to="/"
                        className="btn"
                        type="button"
                        onClick={prevStep}
                    >
                        Home Page
                    </Link>
                </p>
            </div>
        </fieldset>
    )
}

export default Success
