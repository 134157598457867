import React, { useState } from 'react'
import Img from 'gatsby-image'
import ContactForm from '../ContactForm'
import DisplaySuccess from '../SuccessMessage'

const ConnectWithUs = ({ input }) => {
    const [didSubmit, SetDidSubmit] = useState(false)

    const setSubmitState = () => {
        SetDidSubmit(true)
    }
    return (
        <section className="connect">
            <div className="connect__inner">
                <div className="connect__content">
                    <h2>{input.Headline}</h2>
                    <p>{input.SubHeadline}</p>
                    <div className="image-wrapper">
                        {input.Image && (
                            <Img
                                fluid={
                                    input.Image.imageFile.childImageSharp.fluid
                                }
                            />
                        )}
                    </div>
                </div>
                <div className="connect__form">
                    {didSubmit && (
                        <DisplaySuccess
                            message={`Thank you for reaching out! We'll be in touch soon`}
                        />
                    )}

                    {!didSubmit && <ContactForm onsubmit={setSubmitState} />}
                </div>
            </div>
        </section>
    )
}

export default ConnectWithUs
