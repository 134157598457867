import React from 'react'
import Img from 'gatsby-image'

const SpeakerCard = ({ speaker }) => {
    return (
        <article className="speaker-card">
            <div className="speaker-card__img">
                <Img fluid={speaker.Image.imageFile.childImageSharp.fluid} />
            </div>
            <div className="speaker-card__ribbon">
                <p>
                    <span>{speaker.Name}</span>
                    <span>{speaker.Title}</span>
                </p>
            </div>
        </article>
    )
}

export default SpeakerCard
