import React from 'react'
import ValueStackItem from './ValueStackItem'

const ValueStack = ({ input }) => {
    const modifier =
        input.__typename === 'STRAPI_ComponentValueStackSection'
            ? 'numbers'
            : 'icons'

    return (
        <section className="value-stack">
            <div className="value-stack__inner">
                {input.Values.map(item => (
                    <ValueStackItem
                        key={item.id}
                        item={item}
                        modifier={modifier}
                    />
                ))}
            </div>
        </section>
    )
}

export default ValueStack
