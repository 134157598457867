import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PinIcon from '../../../assets/svg/pin-icon.svg'
import ManagersIcon from '../../../assets/svg/managers-icon.svg'
import AgendaDay from './AgendaDay'
import Img from 'gatsby-image'

const FULL_AGENDA_QUERY = graphql`
    query FullAgendaQuery {
        strapi {
            agenda {
                AgendaDay {
                    DayNumber
                    DayDate
                    id
                    AgendaItem {
                        id
                        Time
                        Title
                        Speaker
                        SpeakerImages {
                            id
                            url
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 64) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        IconImage {
                            url
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 64) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        coffeeMug: file(relativePath: { eq: "mug-coffee.png" }) {
            id
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const FullAgenda = ({ input }) => {
    const {
        strapi: {
            agenda: { AgendaDay: agendaDays },
        },
        coffeeMug,
    } = useStaticQuery(FULL_AGENDA_QUERY)

    return (
        <section className="agenda">
            <div className="agenda__inner">
                <div className="agenda__header">
                    <div className="agenda__moniker">
                        <PinIcon />
                        <p>Courtyard by Marriott, La Vista, NE </p>
                    </div>
                    <div className="agenda__moniker">
                        <ManagersIcon />
                        <p>Store Managers & Franchisees</p>
                    </div>
                </div>
                <div className="agenda__container">
                    {agendaDays.map(day => (
                        <AgendaDay key={day.id} day={day} />
                    ))}
                </div>
            </div>
            <Img
                fluid={coffeeMug.childImageSharp.fluid}
                className="agenda__mug"
                style={{ position: `absolute` }}
            />
        </section>
    )
}

export default FullAgenda
