import React from 'react'
import Vimeo from '@u-wave/react-vimeo'
import { Link } from 'gatsby'

const SponsorLeadIn = ({ input }) => {
    return (
        <section className="sponsor-leadin">
            <div className="sponsor-leadin__inner">
                <h3>{input.LeadInText}</h3>
                <Link to={input.CTA.ButtonLink} className="btn">
                    {input.CTA.ButtonText}
                </Link>
                <div className="sponsor-leadin__video">
                    <Vimeo
                        video={input.VideoLink}
                        showTitle={false}
                        showByline={false}
                    />
                </div>
                <p>{input.LeadInOutText}</p>
            </div>
        </section>
    )
}

export default SponsorLeadIn
