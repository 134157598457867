import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import Logrow from '../assets/svg/scooters-con-2020.svg'

const Header = ({ siteTitle }) => {
    useEffect(() => {}, [])

    return (
        <header className="header">
            <div className="header__inner">
                <div className="header__logo">
                    <Link to="/">
                        <Logrow />
                    </Link>
                </div>
            </div>
        </header>
    )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
