import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../components/Seo'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const MISSING_PAGE_QUERY = graphql`
    query MissingQuery {
        heroImage: file(relativePath: { eq: "slim-one.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        coffeeSpill: file(relativePath: { eq: "coffee-spill.jpeg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const NotFoundPage = () => {
    const { heroImage, coffeeSpill } = useStaticQuery(MISSING_PAGE_QUERY)

    return (
        <>
            <SEO title="404: Not Found" />
            <section className="hero hero--slim">
                <div className="hero__inner">
                    <div className="hero__image">
                        <Img fluid={heroImage.childImageSharp.fluid} />
                    </div>
                    <div className="hero__overlay"></div>
                    <div className="hero__content">
                        <h1>404</h1>
                    </div>
                </div>
            </section>
            <section className="not-found">
                <div className="not-found__inner">
                    <div className="not-found__content">
                        <h2>Yikes.</h2>
                        <p>
                            Let's try to help you find what was supposed to be
                            here.
                        </p>
                        <p>
                            If this continues, please feel free to email us{' '}
                            <a href="mailto:scooterscoffee@planitomaha.com">
                                scooterscoffee@planitomaha.com
                            </a>
                        </p>
                        <div className="not-found__links">
                            <Link to="/">Homepage</Link>
                            <Link to="/about-conference">About Conference</Link>
                            <Link to="/agenda">Agenda</Link>
                            <Link to="/sponsorship">Sponsorship</Link>
                            <Link to="/contact">Contact Us</Link>
                        </div>
                    </div>
                    <div className="not-found__image">
                        <Img fluid={coffeeSpill.childImageSharp.fluid} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default NotFoundPage
