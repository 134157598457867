import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SpeakerCard from '../SpeakerCard'

const LAST_YEARS_SPEAKERS_QUERY = graphql`
    query {
        strapi {
            lastYearSSpeakers {
                id
                Name
                Title
                Image {
                    url
                    imageFile {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`

const LastYearsSpeakers = ({ input }) => {
    const {
        strapi: { lastYearSSpeakers: speakers },
    } = useStaticQuery(LAST_YEARS_SPEAKERS_QUERY)

    return (
        <>
            <section className="last-speakers">
                <div className="last-speakers__inner">
                    <div className="last-speakers__images">
                        {speakers.map(speaker => (
                            <SpeakerCard key={speaker.id} speaker={speaker} />
                        ))}
                    </div>
                    <div className="last-speakers__content">
                        <h2>{input.Headline}</h2>
                        <p>{input.SubHeadline}</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LastYearsSpeakers
