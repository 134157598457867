import React from 'react'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'
import { Link } from 'gatsby'

const TheVenue = ({ input }) => {
    return (
        <section className="the-venue">
            <div className="the-venue__inner">
                <div className="the-venue__img">
                    {input.Image && (
                        <Img
                            fluid={input.Image.imageFile.childImageSharp.fluid}
                        />
                    )}
                </div>
                <div className="the-venue__content">
                    <div className="the-venue__content-wrapper">
                        <h2>{input.Headline}</h2>
                        <ReactMarkdown source={input.Content} />

                        {input.CTAButton && (
                            <Link
                                to={input.CTAButton.ButtonLink}
                                className="btn"
                            >
                                {input.CTAButton.ButtonText}
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TheVenue
