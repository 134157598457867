import React from 'react'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

const ValueStackItem = ({ item, modifier }) => {
    if (modifier === 'numbers') {
        return (
            <div className={`value-stack-item value-stack-item--${modifier}`}>
                <div className="value-stack-item__inner">
                    <ReactMarkdown source={item.Content} />
                </div>
            </div>
        )
    }

    return (
        <div className={`value-stack-item value-stack-item--${modifier}`}>
            <div className="value-stack-item__inner">
                {item.Icon && (
                    <Img fluid={item.Icon.imageFile.childImageSharp.fluid} />
                )}
                <ReactMarkdown source={item.Content} />
            </div>
        </div>
    )
}

export default ValueStackItem
