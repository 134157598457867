import React from 'react'
import ForgotPassword from '../components/ForgotPassword'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const SLIM_IMG_QUERY = graphql`
    {
        heroImage: file(relativePath: { eq: "slim-one.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const ForgotPasswordPage = () => {
    const { heroImage } = useStaticQuery(SLIM_IMG_QUERY)
    return (
        <>
            <section className="hero hero--slim">
                <div className="hero__inner">
                    <div className="hero__image">
                        <Img fluid={heroImage.childImageSharp.fluid} />
                    </div>
                    <div className="hero__overlay"></div>
                    <div className="hero__content">
                        <h1>Forgot Password</h1>
                    </div>
                </div>
            </section>
            <ForgotPassword />
        </>
    )
}

export default ForgotPasswordPage
