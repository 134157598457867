import React from 'react'
import {
    LargeHero,
    LastYearsSpeakers,
    TheVenue,
    ConnectWithUs,
    SlimHero,
    FullAgenda,
    ValueStack,
    PastSponsors,
    SponsorLeadIn,
    GenericSection,
} from './DynamicZoneComponents'

const DynamicZone = ({ components }) => {
    return components.map((c, index) => {
        switch (c.__typename) {
            case 'STRAPI_ComponentLargeHero':
                return <LargeHero key={c.id} input={c} />
            case 'STRAPI_ComponentLastYearSSpeakers':
                return <LastYearsSpeakers key={c.id} input={c} />
            case 'STRAPI_ComponentTheVenue':
                return <TheVenue key={c.id} input={c} />
            case 'STRAPI_ComponentConnectWithUs':
                return <ConnectWithUs key={c.id} input={c} />
            case 'STRAPI_ComponentSlimHero':
                return <SlimHero key={c.id} input={c} />
            case 'STRAPI_ComponentFullAgenda':
                return <FullAgenda key={c.id} input={c} />
            case 'STRAPI_ComponentValueStackSection':
                return <ValueStack key={c.id} input={c} />
            case 'STRAPI_ComponentValueStackSectionWithIcons':
                return <ValueStack key={c.id} input={c} />
            case 'STRAPI_ComponentPastSponsors':
                return <PastSponsors key={c.id} input={c} />
            case 'STRAPI_ComponentSponsorshipLeadIn':
                return <SponsorLeadIn key={c.id} input={c} />
            case 'STRAPI_ComponentGenericSection':
                return <GenericSection key={c.id} input={c} />
            default:
                return null
        }
    })
}

export default DynamicZone
