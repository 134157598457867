import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../components/Seo'
import DynamicZone from '../components/DynamicZone'
import Registration from '../components/Registration'

const HOME_PAGE_QUERY = graphql`
    query HomePageQuery {
        strapi {
            page(id: "5f2098d41a89da1bdc04bff6") {
                page_title
                content {
                    ... on STRAPI_ComponentLargeHero {
                        __typename
                        id
                        Headline
                        SubHeadline
                        Dates
                        VideoLink
                        HeaderImage {
                            id
                            url
                            imageFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                    ... on STRAPI_ComponentGenericSection {
                        __typename
                        id
                        Headline
                        SubHead
                        Copy
                        GenericImage: Image {
                            url
                            imageFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }

                    ... on STRAPI_ComponentBecomeASponsor {
                        __typename
                        id
                        Headline
                        SubHeadline
                        Image {
                            url
                            imageFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                    ... on STRAPI_ComponentLastYearSSpeakers {
                        __typename
                        id
                        Headline
                        SubHeadline
                    }
                    ... on STRAPI_ComponentTheVenue {
                        __typename
                        id
                        Headline
                        Content
                        Image {
                            url
                            imageFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                    ... on STRAPI_ComponentConnectWithUs {
                        __typename
                        id
                        Headline
                        SubHeadline
                        Image {
                            url
                            imageFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

const IndexPage = () => {
    const {
        strapi: { page: home },
    } = useStaticQuery(HOME_PAGE_QUERY)

    return (
        <>
            <SEO title="Home" />
            <DynamicZone components={home.content} />
        </>
    )
}

export default IndexPage
