import React from 'react'
import Img from 'gatsby-image'

const SlimHero = ({ input }) => {
    return (
        <section className="hero hero--slim">
            <div className="hero__inner">
                <div className="hero__image">
                    {input.Image && (
                        <Img
                            fluid={input.Image.imageFile.childImageSharp.fluid}
                        />
                    )}
                </div>
                <div className="hero__overlay"></div>
                <div className="hero__content">
                    <h1>{input.Headline}</h1>
                </div>
            </div>
        </section>
    )
}

export default SlimHero
