import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import Error from './ErrorMessage'
const CREATE_FORM_ENTRY = gql`
    mutation createContactForm(
        $firstName: String!
        $lastName: String!
        $email: String!
        $comment: String!
    ) {
        createContactForm(
            input: {
                data: {
                    FirstName: $firstName
                    LastName: $lastName
                    Email: $email
                    Comment: $comment
                }
            }
        ) {
            contactForm {
                id
            }
        }
    }
`

const ContactForm = ({ onsubmit }) => {
    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        comment: '',
    })

    const [
        sendRequest,
        { loading: mutationLoading, error: mutationError },
    ] = useMutation(CREATE_FORM_ENTRY, {
        onCompleted: () => {
            onsubmit()
        },
    })

    const saveToState = e => {
        const { name, value } = e.target
        setState(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    const emailRequest = state => {
        const message = {
            to: 'scooterscoffee@planitomaha.com',
            from: 'scooterscoffee@planitomaha.com',
            subject: 'Scooters Coffee Conference 2020 Contact Form Request',
            ...state,
        }

        var formData = new FormData()

        Object.keys(message).forEach(function(key) {
            formData.append(key, message[key])
        })

        var requestOptions = {
            method: 'POST',
            body: formData,
            redirect: 'follow',
        }

        fetch(
            'https://scooters.bozell.com/send/home/contactform',
            requestOptions
        )
    }

    return (
        <form
            method="post"
            onSubmit={async e => {
                e.preventDefault()
                await sendRequest({
                    variables: { ...state },
                }).then(emailRequest(state))
            }}
        >
            <Error error={mutationError} />
            <fieldset disabled={mutationLoading} aria-busy={mutationLoading}>
                <div className="fieldset-wrapper">
                    <div className="input-container input-container--half">
                        <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            required
                            value={state.firstName}
                            onChange={saveToState}
                        />
                        <label htmlFor="firstName">First Name</label>
                    </div>
                    <div className="input-container input-container--half">
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            required
                            value={state.lastName}
                            onChange={saveToState}
                        />
                        <label htmlFor="lastName">Last Name</label>
                    </div>
                    <div className="input-container">
                        <input
                            type="text"
                            name="email"
                            placeholder="Email"
                            required
                            value={state.email}
                            onChange={saveToState}
                        />
                        <label htmlFor="email">Email</label>
                    </div>
                    <div className="input-container">
                        <textarea
                            type="email"
                            name="comment"
                            placeholder="Question or Comment"
                            required
                            value={state.comment}
                            onChange={saveToState}
                            rows="8"
                        />
                        <label htmlFor="comment">Question or Comment</label>
                    </div>
                </div>
                <button className="btn" type="submit">
                    send
                </button>
            </fieldset>
        </form>
    )
}

export default ContactForm
