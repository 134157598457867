import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Facebook from '../assets/svg/facebook.svg'
import Twitter from '../assets/svg/twitter.svg'
import Instagram from '../assets/svg/instagram.svg'
import LinkedIn from '../assets/svg/linkedin.svg'
import Logrow from '../assets/svg/scooters-con-2020.svg'

const FOOTER_QUERY = graphql`
    query Footer_Query {
        strapi {
            pages {
                id
                page_title
                HideFromNav
            }
            themeOption {
                SocialLinks {
                    FacebookURL
                    InstagramURL
                    LinkedInURL
                    TwitterURL
                }
            }
        }
        headerLogo: file(relativePath: { eq: "scooters-logo.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        cupImage: file(relativePath: { eq: "cup.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const Footer = ({ location }) => {
    const {
        strapi: {
            themeOption: { SocialLinks },
        },
        cupImage,
    } = useStaticQuery(FOOTER_QUERY)

    return (
        <footer className="footer">
            <div className="footer-register"></div>
            <div className="footer-main">
                <div className="footer-main__cup">
                    <Img
                        fluid={cupImage.childImageSharp.fluid}
                        imgStyle={{ objectFit: 'contain' }}
                    />
                </div>
                <div className="footer-main__logo">
                    <Logrow />
                </div>
                <div className="footer-main__social">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={SocialLinks.FacebookURL}
                    >
                        <Facebook />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={SocialLinks.TwitterURL}
                    >
                        <Twitter />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={SocialLinks.InstagramURL}
                    >
                        <Instagram />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={SocialLinks.LinkedInURL}
                    >
                        <LinkedIn />
                    </a>
                </div>
                <div className="footer-main__nav"></div>
                <div className="footer-main__copyright">
                    <p>
                        © {new Date().getFullYear()} Scooter's Coffee. All
                        rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
