// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-export-js": () => import("./../src/pages/export.js" /* webpackChunkName: "component---src-pages-export-js" */),
  "component---src-pages-forgot-password-js": () => import("./../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reset-password-js": () => import("./../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

