import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import DynamicZone from '../components/DynamicZone'
import Registration from '../components/Registration'

const Page = ({
    data: {
        strapi: { page },
    },
}) => {
    return (
        <>
            <Seo title={`${page.page_title}`} />
            <DynamicZone components={page.content} />
            <Registration />
        </>
    )
}

export default Page

export const pageQuery = graphql`
    query GET_PAGE($id: ID!) {
        strapi {
            page(id: $id) {
                page_title
                content {
                    ... on STRAPI_ComponentLargeHero {
                        __typename
                        id
                        Headline
                        SubHeadline
                        VideoLink
                        Dates
                        HeaderImage {
                            id
                            url
                            imageFile {
                                childImageSharp
                                {
                                    fluid {...GatsbyImageSharpFluid}
                                }
                            }
                        }
                    }
                    ... on STRAPI_ComponentGenericSection {
                        __typename
                        id
                        Headline
                        SubHead
                        Copy
                        GenericImage: Image {
                            url
                            imageFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                    ... on STRAPI_ComponentBecomeASponsor {
                        __typename
                        id
                        Headline
                        Image {
                            url
                            imageFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        SubHeadline
                    }
                    ... on STRAPI_ComponentLastYearSSpeakers {
                        __typename
                        id
                        Headline
                        SubHeadline
                        _id
                    }
                    ... on STRAPI_ComponentTheVenue {
                        __typename
                        id
                        Content
                        _id
                        Headline
                        Image {
                            url
                            imageFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        CTAButton {
                            ButtonLink
                            ButtonText
                        }
                    }
                    ... on STRAPI_ComponentConnectWithUs {
                        __typename
                        id
                        Headline
                        SubHeadline
                        Image {
                            url
                            imageFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                    ... on STRAPI_ComponentSlimHero {
                        __typename
                        id
                        Headline
                        Image {
                            url
                            imageFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                    ... on STRAPI_ComponentFullAgenda {
                        __typename
                        id
                    }
                    ... on STRAPI_ComponentValueStackSection {
                        __typename
                        id
                        Values {
                            Content
                            id
                        }
                    }
                    ... on STRAPI_ComponentValueStackSectionWithIcons {
                        __typename
                        id
                        Values {
                            Content
                            id
                            Icon {
                                url
                                imageFile {
                                    childImageSharp {
                                        fluid {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on STRAPI_ComponentDownloadSection {
                        id
                        Text
                        Image {
                            url
                            imageFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        FileToDownload {
                            url
                            imageFile {
                                publicURL
                            }
                        }
                    }
                    ... on STRAPI_ComponentPastSponsors {
                        __typename
                        id
                        Images {
                            id
                            url
                            imageFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                    ... on STRAPI_ComponentSponsorshipLeadIn {
                        id
                        LeadInText
                        CTA {
                            ButtonLink
                            ButtonText
                        }
                        VideoLink
                        LeadInOutText
                    }
                }
            }
        }
    }
`
