import React from 'react'
import moment from 'moment'
import AgendaItem from './AgendaItem'

const AgendaDay = ({ day }) => {
    const date = new Date(day.DayDate.replace(/-/g, '/'))
    const formattedDate = moment(date).format('dddd, MMMM Do, YYYY')

    return (
        <section className="agenda-day">
            <div className="agenda-day__inner">
                <div className="agenda-day__header">
                    <span>Day {day.DayNumber}</span>
                    <p>{formattedDate}</p>
                </div>
                <div className="agenda-day__container">
                    {day.AgendaItem.map(item => (
                        <AgendaItem key={item.id} item={item} />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default AgendaDay
